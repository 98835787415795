const egg =
`
 /==\\
|****|
\\====/
 '--'
888888ba                    oo                                  dP    dP                dP   
88     8b                                                       88    88                88
88     88 .d8888b. .d8888b. dP .d8888b. 88d888b. .d8888b. .d888b88    88d888b. dP    dP     
88     88 88ooood8 Y8ooooo. 88 88'   88 88'   88 88ooood8 88'   88    88'   88 88    88        
88    .8P 88.  ...       88 88 88.  .88 88    88 88.  ... 88.  .88    88.  .88 88.  .88 dP
8888888P   88888P'  88888P' dP  8888P88 dP    dP  88888P'  88888P8    88Y8888'  8888P88 88 
oooooooooooooooooooooooooooooooo~~~~.88~oooooooooooooooooooooooooooooooooooooooo~~~~.88~oo
                                d8888P                                          d8888P                                                

    88888888b       dP        888888ba
    88              88        88     8b
   a88aaaa    .d888b88       a88aaaa8P'           
    88        88'   88        88    8b.            
    88        88.  .88 dP     88    .88 dP  
    88888888P  88888P8 88     88888888P 88
   ooooooooooooooooooooooooooooooooooooooo

                                         /@@\\
                                        |@^^@|
                                        \\^@@^/
                                         '--'
`

export default egg;
import React from 'react';



const Resume = () =>{

    return(
        <div>
            <h1>Resume</h1>
        </div>
    )

}

export default Resume;